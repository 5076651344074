.bunner {
	margin: 16px 0px 0px 0px;
	display: flex;
	justify-content: space-between;
	padding: 8px 10px;
	border-radius: 6px;
	background-color: var(--secondary-color);
}

.bunner__text {
	margin-right: 4px;
}

.bunner button {
	white-space: nowrap;
}

@media (max-width: 540.5px){
	.bunner {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
	.bunner__text {
		margin: 0px 0px 8px 0px;
	}
	.bunner__text > br,
	.bunner__text > span {
		display: none;
	}
}