.container {
	max-width: 1130px;
	margin: 12px auto;
}

.container>p {
	margin: 6px 0px 13px 0px;
	font-weight: 500;
	color: var(--textdark-color);
}

.contentBlock {
	margin: 0px 0px 34px 0px;
}

@media (min-width: 1200.5px){
	.container {
		padding: 0 49px;
	}
}

@media (max-width: 767.5px){
	.contentBlock h4 {
		font-size: 16px;
	}
}