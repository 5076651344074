.card {
	padding: 16px 16px 16px 24px;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	border: 0.5px solid var(--fielddisabled-color);
	border-radius: 6px;
}

.info > h3 > span {
	font-weight: 400;
	color: var(--field-color);
	font-size: 16px;
}


.header {
	display: flex;
}

.header>p {
	color: var(--textdark-color);
	font-weight: 500;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	border-radius: 6px;
	background-color: var(--secondary-color);
	font-size: 20px;
}

.na {
	display: flex;
}

.na > p {
	color: var(--error-color);
}

@media (max-width: 950px) {
	.icon {
		display: flex;
	}
}

@media (max-width: 550px) {
	.icon {
		display: flex;
	}
}
