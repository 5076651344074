.container {
	margin: 0 auto;
	max-width: 392px;
	width: 100%;
}

/* @media (min-width: 767.5px) {
	.wrap {
		display: flex;
	}
} */

.titleDesktopColor {
    color: var(--fieldhover-color) !important;
}



.avatar {
	position: relative;
	padding: 17px 0 8px 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.info {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin: 0;
}

/* @media (max-width: 767.5px) {
	.info {
		align-items: center;
		margin: 0;
	}
} */



.name {
	color: var(--textdark-color);
	font-weight: 600;
	margin: 5px 0px 0px 0px;
	font-size: 25px;
	font-size: clamp(20px, 5vw, 25px);
	text-align: center;
}

.email,
.eid {
	margin: 4px 0px 0px 0px;
	color: var(--field-color);
}