.notice {
	text-align: center;
	max-width: 245px;
	margin: 0px auto 0 auto;
}

.notice > i {
	font-size: 40px;
	color: var(--fielddisabled-color);
}

.dark {
	color: var(--textdark-color);
	font-weight: 500;
	margin: 0px 0px 5px 0px;
}
