.links {
	margin: 18px 0 20px 0;
	display: flex;
	align-items: center;
	justify-content: left;
}
.link {
	position: relative;
	display: flex;
	justify-content:center;
	align-items: center;
	padding: 8px 14px;
	margin: 0px 16px 0px 0px;
	color: var(--fieldhover-color);
	cursor: pointer;
	border-radius: 6px;
	border: none;
	outline: none;
	font-size: 1em;
	flex-grow: 0;
}
.link.active,
.link:active {
	padding: 8px 13px;
	color: var(--primary-color);
	background-color: var(--secondary-color);
	font-weight: 600;
}

.link:hover {
	background-color: var(--secondary-color);
	font-weight: 600;
	padding: 8px 13px;
	border: none;
}

.link.disabled {
	pointer-events: none;
	color: var(--fielddisabled-color);
}