.training {
	position: relative;
	display: flex;
	align-items: center;
	height: 96px;
	width: 100%;
}

.poster {
	width: 96px;
	height: 100%;
	overflow: hidden;
	position: relative;
	flex-shrink: 0;
}

.text {
	flex-grow: 1;
	padding: 0 12px 0 8px;
}

.poster img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 6px;
}

.teamName {
	color: var(--textdark-color);
	margin: 0px 0px 3px 0px;
	-webkit-line-clamp: 1;
	/* Число отображаемых строк */
	display: -webkit-box;
	/* Включаем флексбоксы */
	-webkit-box-orient: vertical;
	/* Вертикальная ориентация */
	overflow: hidden;
}

.videoName {
	color: var(--textdark-color);
	font-weight: 600;
	margin: 0px 0px 10px 0px;
	-webkit-line-clamp: 2;
	/* Число отображаемых строк */
	display: -webkit-box;
	/* Включаем флексбоксы */
	-webkit-box-orient: vertical;
	/* Вертикальная ориентация */
	overflow: hidden;
}


@media (min-width: 767.5px) {
	.poster {
		width: 108px;
	}

	.training {
		height: 108px;
	}

	.videoName {
		font-size: 20px;
	}

	.teamName {
		font-size: 16px;
	}
}

@media (min-width: 991.5px) {
	.training {
		display: block;
		height: auto;
	}

	.poster {
		height: auto;
		padding-top: 100%;
		width: 100%;
		margin: 0px 0px 6px 0px;
	}

	.videoName {
		margin: 0px 0px 10px 0px;
		font-size: 16px;
	}

	.close {
		position: absolute;
		top: 8px;
		right: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
}