.table {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	gap: 24px;
}

.table.vr {
	grid-template-columns: 1fr;
}

@media (min-width: 991.5px) {
	.table {
		display: grid;
		grid-template-columns: repeat(5, minmax(120px, 1fr));
		gap: 16px;
		justify-content: center;
	}

	.table.vr {
		grid-template-columns: repeat(6, minmax(110px, 1fr));
	}
}

@media (min-width: 1200px) {
	.table {
		display: grid;
		grid-template-columns: repeat(5, minmax(120px, 1fr));
		grid-template-rows: auto;
		gap: 16px;
	}
}

@media (min-width: 1440.5px) {
	.table {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
		grid-template-rows: auto;
		gap: 24px;
	}
}

@media (min-width: 1730.5px) {
	.table {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(187px, 1fr));
		grid-template-rows: auto;
		gap: 24px;
	}
}

/*.item {
	display: flex;
	justify-content:center;
	align-items: baseline;
}*/

.item {
	width: 100%;
}