.container {
	position: relative;
	display: inline-flex;
}

.avatar {
	position: relative;
	display: inline-flex;
	justify-content:center;
	align-items: center;
	width: 104px;
	height: 104px;
	border-radius: 50%;
	background-color: var(--secondary-color);
	overflow: hidden;
	filter: drop-shadow(0px 0px 3px rgba(46, 58, 75, 0.2));
}

.avatar > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.avatar input {
	position: absolute;
	bottom: 0;
	width: 65px;
	height: 22px;
	display: flex;
	justify-content:center;
	align-items: center;
	border-bottom-left-radius: 42px;
	border-bottom-right-radius: 42px;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	font-size: 0;
	z-index: 500;
	cursor: pointer;
}

.avatar:hover .overlay {
	opacity: 1;
	visibility: visible;
}
.avatar:hover input {
	visibility: visible;
}

.avatar > i {
	font-size: 64px;
	color: var(--field-color);
}

.avatar > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.overlay {
	position: absolute;
	bottom: 0px;
	right: 0;
	left: 0;
	padding: 2px 3px;
	display: flex;
	justify-content:center;
	align-items: center;
	background-color: rgba(46, 58, 75, 0.5);
	border-bottom-left-radius: 20px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
	z-index: 5;
}
.overlay > i {
	font-size: 17px;
	color: var(--textlight-color);
}

@media (max-width: 768px){
	.overlay {
		visibility: visible;
		opacity: 1;
	}
}

.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content:center;
	align-items: center;
	background-color: var(--secondary-color);
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
	z-index: 100;
}

.loading.active {
	opacity: 1;
	visibility: visible;
}

.delete {
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content:center;
	align-items: center;
	background-color: var(--secondary-color);
	border: 3px solid #fff;
	border-radius: 50%;
	z-index: 100;
	cursor: pointer;
}

.delete > i {
	font-size: 18px;
	color: var(--fieldhover-color);
	font-weight: 900;
}

.delete:hover > i {
	color: var(--textdark-color);
}