.aside {
	min-height: 100vh;
	width: 100%;
	max-width: 280px;
	padding: 24px 0px;
	background-color: var(--secondary-color);
	display: flex;
	flex-direction: column;
	position: relative;
}

.aside__plan {
	position: absolute;
	top: 20px;
	right: 16px;
	display: flex;
	justify-content: flex-end;
}

.logo {
	width: 130px;
	margin: 0 32px 16px 32px;
	flex-basis: content;
	cursor: pointer;
}



.flex__item {
	flex-basis: content;
	flex-grow: 1;
}

.menu {
	margin: 0px 0px 0px 0px;
}

.gray__logo {
	display: flex;
	margin: 20px 32px 0 32px;
}

.gray__logo>img {
	cursor: pointer;
}

.gray__logo>img:hover {
	filter: brightness(0.8);
}

.gray__logo>p {
	color: var(--field-color);
	margin: 0px 4px 0px 0px;
}

.scroll__block {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

@media (max-height: 900px) {
	.scroll__block {
		height: calc(100vh - 80px) !important;
	}
}

.platform__links a {
	padding-right: 3px;
	margin: 0;
	display: flex;
	flex-basis: calc(50% - 2px);
	justify-content: center;
}

.platform__links {
	padding: 20px 24px 0 24px;
}

.platform__links>div {
	gap: 4px;
}

@media (max-width: 1250px) {

	.platform__links a {
		flex-basis: 100%;
	}

	.aside {
		max-width: 200px;
	}

	.aside__plan {
		right: 8px;
	}

	.logo {
		width: 100px;
		margin: 0 16px 16px 16px;
	}

	.gray__logo {
		margin: 20px 16px 0 16px;
	}

	.platform__link {
		flex-basis: 100%;
		justify-content: center;
	}
}

@media (max-width: 920px) {

	.platform__links a {
		flex-basis: calc(50% - 2px);
	}

	.aside {
		max-width: 150px;
	}

	.logo {
		width: 100px;
		margin: 0 16px 16px 16px;
	}
}