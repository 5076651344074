.icon {
	display: flex;
	justify-content:center;
	align-items: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: var(--secondary-color);
	font-size: 14px;
	font-weight: 900;
	color: var(--field-color);
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.icon:hover {
	color: var(--textdark-color);
}

.icon.dark {
	background: var(--textdark-color);
	color: var(--bgtextbtn-color);
}

.icon.dark:hover {
	opacity: 0.8;
}