.search {
	max-width: 379px;
	width: 100%;
	margin: 0 0 20px 0;
}
.header h3{
	font-size: 30px;
	margin-bottom: 23px;
}


.button_wrapper{
	padding-top: 24px; 
}

  
