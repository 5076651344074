.container {
	padding: 8px 16px;
	background-color: var(--textdark-color);
	box-shadow: 2px 4px 10px rgba(46, 58, 75, 0.16), 0px 0px 4px rgba(46, 58, 75, 0.12);
	border-radius: 6px;
	color: #fff;
}

.date {
	font-size: 12px;
	opacity: 0.7;
}

.date > i,
.time > i {
	font-size: 14px;
	margin: 0px 8px 0px 0px;
}

.time > i {
	color: #4BC0A3;
}