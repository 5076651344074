.container {
	margin: 13px 0px 0px 0px;
}

.show {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.show>p {
	color: var(--fieldhover-color);
	font-weight: 500;
}

.show>i {
	color: var(--fieldhover-color);
	font-size: 20px;
}

.form {
	display: flex;
	flex-direction: column;
	margin: 8px 0px 0px 0px;
}

.row {
	margin: 5px 0px 0px 0px;
}

.btn {
	margin: 13px auto 0 auto;
}

@media (min-width: 768px) {
	/*.form {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}*/

	.row {
		margin: 5px 0px 0px 0px;
		/*flex-basis: 184px;*/
	}

	.btn {
		margin: 13px 0px 0px auto;
	}
}