.cards {
	display: flex;
	/*justify-content: space-between;*/
	width: 100%;
}



.card {
	flex-basis: 32%;
	flex-shrink: 0;
	margin-right: 2%;
}

.cards > .card:last-child {
	margin-right: 0;
}

@media (max-width: 768.5px) {
	.cards {
		flex-wrap: wrap;
	}

	.card {
		flex-basis: 100%;
		margin: 0px 0px 16px 0px;
	}
}