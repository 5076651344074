.btn {
	position: relative;
	border: none;
	white-space: nowrap;
	border-radius: 6px;
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.035em;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: fit-content;
	display: inline-flex;
	align-items: center;
	height: 36px;
}

.btn.primary {
	padding: 0px 16px;
	background-color: var(--primary-color);
	color: var(--secondary-color);
}

.btn.secondary {
	padding: 0px 18.5px;
	border: 1.5px solid var(--primary-color);
	background-color: var(--bgsecondary-color);
	color: var(--primary-color);
}

.btn.texthover {
	padding: 0px 14px;
	background-color: transparent;
	color: var(--primary-color-light);
}

.btn.textbtn {
	padding: 0px 14px;
	background-color: var(--bgtextbtn-color);
	color: var(--primary-color);
	box-shadow: none;
}

.btn.linkbtn {
	padding: 0px 0px;
	background: transparent;
	color: var(--primary-color);
	box-shadow: none;
}

.btn:hover {
	/*transform: translateY(2px);*/
	/*transform: scale(0.98);*/
	box-shadow: 0px 8px 10px rgba(0, 134, 255, 0.12), 0px 3px 14px rgba(0, 134, 255, 0.1), 0px 5px 5px rgba(0, 134, 255, 0.18);
}

.btn.textbtn:hover {
	background-color: var(--secondary-color);
	transform: none;
	box-shadow: none;
}

.btn.linkbtn:hover {
	background-color: transparent;
	color: var(--primary-hover-color);
	transform: none;
	box-shadow: none;
}

.btn.texthover:hover {
	background: #0086FF33;
	transform: none;
	box-shadow: none;
}

.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}



.icon i::before {
	/*transition: all 0.2s ease-in-out;*/
	font-size: 20px;
	color: inherit;
}

.iconInsights i::before {
    font-size: 24px !important;
}

.btn.primary .icon i::before {
	color: var(--textlight-color);
}

.btn.secondary .icon i::before,
.btn.textbtn .icon i::before {
	color: var(--primary-color);
	color: inherit;
}

.btn.primary:active {
	background-color: var(--primary-color-light);
}

.btn.secondary:active {
	border-color: var(--primary-color-light);
	color: var(--primary-color-light);
}

.btn.textbtn:active {
	background-color: var(--bgtextbtn-color);
	color: var(--primary-color-light);
}

.btn.linkbtn:active {
	background-color: transparent;
	color: var(--primary-color-light);
}

.btn.texthover:active {
	background: transparent;
	color: var(--primary-color);
}

.btn.secondary:active>.icon i::before,
.btn.textbtn:active>.icon i::before,
.btn.linkbtn:active>.icon i::before {
	color: var(--primary-color-light);
	color: inherit;
}

.btn.primary:disabled {
	background-color: var(--fielddisabled-color);
	color: var(--textdark-color-desabled);
	cursor: not-allowed;
}

.btn.primary:disabled>.icon i::before {
	color: var(--textdark-color-desabled);
}

.btn.secondary:disabled {
	background-color: var(--bgsecondary-color);
	color: var(--fielddisabled-color);
	border-color: var(--fielddisabled-color);
	cursor: not-allowed;
}

.btn.secondary:disabled>.icon i::before {
	color: var(--fielddisabled-color);
}

.btn.textbtn:disabled {
	background-color: var(--bgtextbtn-color);
	color: var(--fielddisabled-color);
	cursor: not-allowed;
}

.btn.textbtn:disabled>.icon i::before {
	color: var(--fielddisabled-color);
}

.btn.linkbtn:disabled {
	background-color: transparent;
	color: var(--fielddisabled-color);
	cursor: not-allowed;
}

.btn.linkbtn:disabled>.icon i::before {
	color: var(--fielddisabled-color);
}

.btn:disabled.btn:hover {
	transform: none;
	box-shadow: none;
}

/* .icon {
	width: 16px;
	height: 16px;
} */

/* .icon.left {
	margin-right: 6px;
}

.icon.right {
	margin-left: 6px;
} */

/* .icon.left {
    margin-right: 8px; 
} */

.icon.right {
    margin-left: 8px; /* Якщо іконка праворуч */
}

.spinner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

