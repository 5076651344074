.rows {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 18px;
}
.column__top {
	flex-basis: 49%;
	flex-grow: 0;
}
/*.column__top:nth-child(1) {
	padding-right: 12px;
}
.column__top:nth-child(2) {
	padding-left: 12px;
}*/
.column__bottom {
	padding: 24px 0px 0px 0px;
	flex-basis: 100%;
	flex-grow: 0;
}

@media (max-width: 768px){
	.column__top {
		padding: 0px 0px !important;
		flex-basis: 100%;
		margin: 0px 0px 24px 0px;
	}
	.column__bottom {
		padding: 0px 0px 0px 0px;
	}
}

@media (max-width: 550px){
	.column__top {
		margin: 0px 0px 16px 0px;
	}
}