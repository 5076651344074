.container {
	max-width: 392px;
	width: 100%;
}

.container > p {
	margin: 30px 0px 0px 0px;
	font-weight: 500;
	color: var(--textdark-color);
}

.actions {
	display: flex;
	justify-content: center;
	padding: 8px 0px 0px 0px;
}

.action {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 10px;
	cursor: pointer;
}

.action > i {
	font-size: 24px;
	color: var(--textdark-color);
}

.action > p {
	margin: 4px 0px 0px 0px;
}

.teams {
	position: relative;
	padding: 0 24px;
	display: flex;
	flex-direction: column;
}

.team {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
}

.team > p {
	width: 85%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--textdark-color);
}

.role {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0px 0px 0px;
}

.role > p {
	font-weight: 500;
	color: var(--textdark-color);
}

.switch {
	display: flex;
	align-items: center;
}

.emptyState {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
  }
  
  .emptyImage {
	width: 208px;
	margin-bottom: 20px;
  }

  @media (max-width: 770px) {
	.emptyImage {
		width: 120px;
	  }
  }
  