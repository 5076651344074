.table {
	height: 350px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	border: 0.5px solid var(--field-color);
	border-radius: 6px;
	position: relative;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 24px 0px 24px;
}

.header > p {
	font-weight: 500;
	color: var(--textdark-color);
}

.chart {
	height: 255px;
	width: 100%;
}
.chartLabel {
	position: absolute;
	left: 32px;
	bottom: 260px;
}

@media screen and (max-width: 991px) {
	.header {
		padding: 12px 12px 0px 12px;
	}
	.table {
		height: 400px;
	}
	.chart {
		height: 300px;
	}
	.chartLabel {
		bottom: 305px;
	}
}
	